<script>
import service from '@/api/service'
const API_GUIAS = '/api/guias-usuario'
export default {
  name: 'GuiasUsuarioMainView',
  data () {
    return {
      guias_usuario: [
        {
          title: 'Acceso',
          desc: 'Guía para el acceso al sistema.',
          url: 'acceso',
          filename: 'guía_acceso',
          ext: 'pdf',
          textColor: 'text-primary',
          icon: 'layers'
        }
      ],
      guias_inversionista: [
        {
          title: 'Envío de comprobantes',
          desc: 'Guía para el envío y verificación de comprobantes fiscales digitales (CFDI).',
          url: 'enviar-comprobante',
          filename: 'envio_comprobante',
          ext: 'pdf',
          textColor: 'text-primary',
          icon: 'layers'
        },
        {
          title: 'Envío de constancia',
          desc: 'Guía para el envío y verificación de la constancia de situación fiscal (CSF).',
          url: 'enviar-constancia',
          filename: 'envio_constancia',
          ext: 'pdf',
          textColor: 'text-primary',
          icon: 'layers'
        }
      ],
      guias_administrador: [
        {
          title: 'Agregar claves SAT',
          desc: 'Guía para agregar las claves de validación de conceptos del SAT.',
          url: 'agregar-claves-sat',
          filename: 'agregar_claves_sat',
          ext: 'pdf',
          textColor: 'text-primary',
          icon: 'layers'
        }
      ],
      loading: false
    }
  },
  methods: {
    async onDownload (url, filename, extension) {
      this.loading = true

      const { data } = await service({
        _method: 'GET',
        url: `${API_GUIAS}/${url}`,
        responseType: 'blob'
      })

      this.loading = false

      this.$onDownload(data, `${filename}.${extension}`)
    }
  }
}
</script>

<template>
  <base-view title="Guías de usuario" icon="book">
    <div>
      <check-authorization :requiresAuthorizations="['acceso guias']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Guías generales de usuario
          </b-card-header>
          <b-card-body>
            <b-row class="row">
              <b-col cols="12" lg="4" class="mb-4" v-for="item in guias_usuario" :key="item.id">
                <div class="card lift lift-sm h-100 my-cursor" @click="onDownload(item.url, item.filename, item.ext)">
                  <div class="card-body">
                    <h5 class="card-title mb-2" :class="item.textColor">
                      <i class="mr-2" :data-feather="item.icon"></i>
                      {{ item.title }}
                    </h5>
                    <p class="card-text mb-1">
                      {{ item.desc }}
                    </p>
                  </div>
                  <div class="card-footer">
                    <div class="small text-muted">{{ item.filename }}.{{ item.ext }}</div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </div>
      </check-authorization>
      <check-authorization :requiresAuthorizations="['acceso guias inversionista']" hide>
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Guías del usuario inversionista
          </b-card-header>
          <b-card-body>
            <b-row class="row">
              <b-col cols="12" lg="4" class="mb-4" v-for="item in guias_inversionista" :key="item.id">
                <div class="card lift lift-sm h-100 my-cursor" @click="onDownload(item.url, item.filename, item.ext)">
                  <div class="card-body">
                    <h5 class="card-title mb-2" :class="item.textColor">
                      <i class="mr-2" :data-feather="item.icon"></i>
                      {{ item.title }}
                    </h5>
                    <p class="card-text mb-1">
                      {{ item.desc }}
                    </p>
                  </div>
                  <div class="card-footer">
                    <div class="small text-muted">{{ item.filename }}.{{ item.ext }}</div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </div>
      </check-authorization>
      <check-authorization :requiresAuthorizations="['acceso guias administrador']" hide>
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Guías del usuario administrador
          </b-card-header>
          <b-card-body>
            <b-row class="row">
              <b-col cols="12" lg="4" class="mb-4" v-for="item in guias_administrador" :key="item.id">
                <div class="card lift lift-sm h-100 my-cursor" @click="onDownload(item.url, item.filename, item.ext)">
                  <div class="card-body">
                    <h5 class="card-title mb-2" :class="item.textColor">
                      <i class="mr-2" :data-feather="item.icon"></i>
                      {{ item.title }}
                    </h5>
                    <p class="card-text mb-1">
                      {{ item.desc }}
                    </p>
                  </div>
                  <div class="card-footer">
                    <div class="small text-muted">{{ item.filename }}.{{ item.ext }}</div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<style scoped>

</style>
